import React from "react";
import "./switchEmail.css";
import Spinner from "../../Common/Spinner";
import SwitchEmailForm from "./SwitchEmailForm";
export default function SwitchEmail({ id, onCancel, fetchUserOrders }) {
  const [loading, setLoading] = React.useState(false);
  const [didProceed, setDidProceed] = React.useState(false);
  return (
    <>
      {didProceed ? (
        <SwitchEmailForm
          id={id}
          onCancel={onCancel}
          fetchUserOrders={fetchUserOrders}
        />
      ) : (
        <div>
          <p>
            Switching the email will cancel the current order and create a new
            one linked to the provided email. The subscription duration and
            other details will remain unchanged. Are you sure you want to
            proceed?
          </p>
          <div className="cancel-btn-container">
            {loading ? (
              <button className="downloadBtn" style={{ alignItems: "center" }}>
                Processing
                <Spinner height={17} width={17} color={"#64748b"} />
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="downloadBtn"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  setLoading={setLoading}
                  className="cancelBtn"
                  onClick={() => setDidProceed(true)}
                >
                  Proceed
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
