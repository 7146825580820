import "../../SubscriptionForm.css"
import FullNameInput from "./Input/FullName";
import EmailInput from "./Input/Email";
import DurationSelect from "./Select/Duration";
import AutoPaySelect from "./Select/AutoPay";
import Price from "./Price";
import PayButton from "./Button/PayButton";

const SubscriptionForm = ({
  handleSubmit,
  clientSecret,
  formSubmittedAutoPay,
  name,
  setName,
  duration,
  setDuration,
  email,
  setEmail,
  isAutoPay,
  setIsAutoPay,
  svgRef,
  handleMouseEnter,
  handleMouseLeave,
  currency,
  prices,
  AdobePrices,
  dashBoard,
  isLoading,
}) => {
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={`inputs ${
          !clientSecret && formSubmittedAutoPay === false && !dashBoard ? "shown" : ""
        }`}
      > 
        {!clientSecret && formSubmittedAutoPay === false && !dashBoard && (
          <div className='inputs-subdiv'>
            <h1 style={{ marginBottom: 0, textAlign: "left" }}>
              Submit an application
            </h1>
            <p>
              In a rush? All applications are now reviewed & delivered instantly.
              <br></br>
              <br></br>
              You get the genuine Adobe CC All Apps plan applied to your own
              Adobe account. What's included? All Adobe applications (except
              Substance 3D), 80GB cloud storage, Firefly AI with 250 credits
              renewed every month, access to beta apps, and 2 active
              simultaneous sessions (with 2 mobile sessions as well). It's the
              authentic and official subscription, no need to use a VPN.
            </p>
            <div className='input-container'>
              <FullNameInput name={name} setName={setName} />
              <EmailInput email={email} setEmail={setEmail} />
              <DurationSelect duration={duration} setDuration={setDuration} />
              <AutoPaySelect
                isAutoPay={isAutoPay}
                setIsAutoPay={setIsAutoPay}
                svgRef={svgRef}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
              />
            </div>
            <div>
              <Price
                currency={currency}
                prices={prices}
                duration={duration}
                AdobePrices={AdobePrices}
              />
            </div>
            <PayButton
              email={email}
              name={name}
              clientSecret={clientSecret}
              formSubmittedAutoPay={formSubmittedAutoPay}
              isLoading={isLoading}
            />
          </div>
        )}
      </form>
    </>
  );
};

export default SubscriptionForm;