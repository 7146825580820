import axios from "axios";
import React from "react";
import toast from "react-hot-toast";
import constants from "../../../constants";
import Spinner from "../../Common/Spinner";
import { Sleep } from "../../../utils";

export default function SwitchEmailForm({ id, onCancel, fetchUserOrders }) {
  const [loading, setLoading] = React.useState(false);
  const handleSwitchEmail = async (event) => {
    try {
      event.preventDefault();
      const email = event.target[0].value;
      if (!email) toast.error("Name and email are required!");
      setLoading(true);
      const res = await axios.patch(
        constants.API_URL + "/orders/switch/" + id,
        {
          email: email,
          add_to_console: true,
        },
        {
          withCredentials: true,
        }
      );
      if (res.status === 200) {
        toast.success("Email switched successfully!");
        fetchUserOrders();
        await Sleep(800);
        onCancel();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <form action="" onSubmit={handleSwitchEmail}>
        <div className="switch-email-input-container">
          <label style={{ fontWeight: 600 }}>Email</label>
          <input
            type="email"
            placeholder="Email to switch"
            style={{
              borderRadius: "5px",
            }}
            className="switchEmailInput"
          />
        </div>

        {loading ? (
          <div className="cancel-btn-container">
            <button className="switchBtn">
              Processing
              <Spinner height={17} width={17} color={"#7dd3fc"} />
            </button>
          </div>
        ) : (
          <div className="cancel-btn-container">
            <button type="button" onClick={onCancel} className="downloadBtn">
              Cancel
            </button>
            <button className="switchBtn">Switch</button>
          </div>
        )}
      </form>
    </div>
  );
}
