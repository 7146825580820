import "../Main/Desktop/Payment/Payment.css"
import Footer from "../Footer/Footer"
const Mobile = () => {
    return <>
    <div className='mobile-main'>
        <div className='mobile-main-main'>
          <div className={`payment-container-done-mobile shown`}><div className='success-div'><div class="animation-ctn">
            <div style={{ display:'flex', width:'100%', flexDirection:'column' }}>
              <img src='/images/tick.svg' alt='tick' style={{ height:80, alignSelf:'center' }} />
            </div>
          </div>{<h2 style={{ textAlign: 'center', marginTop: 60 }}>Payment successful.</h2>}{<p style={{ textAlign: 'center', marginTop: 60 }}>You should receive your invitation email within 24 hours.</p>}{<p style={{ textAlign: 'center', marginTop: 10 }}>If any problem, contact us by email:</p>}{<h3 style={{ textAlign: 'center', marginTop: 20 }}>help@cheapcc.net</h3>}</div></div>
        </div>
        <Footer style={{ width: 'calc(100% - 100px)', marginTop: 20, marginBottom: 8 }} mobile={true} />
      </div>
    </>
}

export default Mobile