import "./TermsOfService.css"
import React from "react";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import TermsOfServiceSvg from "./TermsOfServiceSvg";

function TermsOfService() {
  return (
    <div
      className='refund-container'
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gap: 30,
      }}
    >
      <Link
        to='/'
        className='main-refund'
        style={{
          flexDirection: "row",
          textDecoration: "none",
          justifyContent: "flex-start",
          gap: 20,
          padding: 20,
          width: "fit-content",
          minWidth: "fit-content",
        }}
      >
        <TermsOfServiceSvg />
        <h2 style={{ textDecoration: "none", color: "black" }}>
          Go back to home
        </h2>
      </Link>
      <div className='main-refund' style={{ marginTop: 20 }}>
        <h1>Terms of Service</h1>
        <p>
          <strong>Effective since: 16/09/2024</strong>
        </p>
        <p>
          Welcome to cheapcc.net. These Terms of Service govern your use of our
          website and services. By accessing or using our services, you agree to
          be bound by these terms and all terms incorporated by reference. If
          you do not agree to all of these terms, do not use our services.
        </p>

        <h2>1. Service Description</h2>
        <p>
          We are an intermediary platform that connects visitors to our site
          with partner educational organizations, which have access to
          subscriptions for creative software such as Adobe® Creative Cloud.
        </p>

        <h2>2. Eligibility</h2>
        <p>
          You must be at least 18 years old to use our services. By agreeing to
          these Terms, you represent and warrant that you are of legal age to
          form a binding contract with us.
        </p>

        <h2>3. Subscription Terms</h2>
        <p>
          When you purchase a subscription through cheapcc.net, you are paying
          admission fees to our partner educational organizations. You have the
          option to choose between automatic renewal and a one-time payment:
        </p>
        <ul>
          <li>
            <strong>Automatic Renewal:</strong> Your subscription will
            automatically renew at the end of each subscription period, unless
            you cancel it before the renewal date.
          </li>
          <li>
            <strong>One-Time Payment:</strong> If you choose not to activate
            automatic renewal, your subscription will end at the conclusion of
            the subscription period with no further charges.
          </li>
        </ul>

        <h2>4. Payment</h2>
        <p>
          All payments are processed securely. You agree to pay the specified
          fees at the time of your purchase, and you acknowledge that prices may
          be subject to change with at least 5 days' advance notice.
        </p>

        <h2>5. Cancellation and Refund Policy</h2>
        <p>
          You can cancel your subscription at any time. For details on refunds,
          please refer to our <a href='/refund-policy'>Refund Policy</a>.
        </p>

        <h2>6. Intellectual Property Rights</h2>
        <p>
          Adobe®, Adobe Substance 3D®, Adobe Creative Cloud® are either
          registered trademarks or trademarks of Adobe® in the United States
          and/or other countries. We are not directly affiliated with Adobe
          Systems Software Ireland Ltd.
        </p>

        <h2>7. Compliance with Laws</h2>
        <p>
          You agree to comply with all applicable laws and regulations in
          connection with your use of our services.
        </p>

        <h2>8. Limitation of Liability</h2>
        <p>
          We shall not be liable for any indirect, incidental, special,
          consequential or punitive damages, including without limitation, loss
          of profits, data, use, goodwill, or other intangible losses, resulting
          from your access to or use of or inability to access or use the
          services.
        </p>

        <h2>9. Changes to Terms of Service</h2>
        <p>
          We reserve the right to modify these terms at any time. We will
          provide notice of these changes by updating the effective date at the
          top of the terms. Your continued use of our services after such
          changes have been notified will constitute your consent to those
          changes.
        </p>

        <h2>10. Contact Information</h2>
        <p>
          If you have any questions about these Terms of Service, please contact
          us at tos@cheapcc.net.
        </p>

        <p>
          By using our services, you acknowledge that you have read and
          understood these Terms of Service and agree to be bound by them.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default TermsOfService;
