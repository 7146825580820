const Total = ({ currency, bulk, prices, duration, totalPrice }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h3
          style={{
            fontWeight: 600,
            textWrap: "nowrap",
            width: "fit-content",
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            color: "#30313D",
          }}
        >
          Total (tax included):
        </h3>
        <h3
          style={{
            fontWeight: 600,
            textWrap: "nowrap",
            width: "fit-content",
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            color: "#30313D",
          }}
        >
          {currency}
          {bulk === false ? prices[duration].toFixed(2) : totalPrice.toFixed(2)}
        </h3>
      </div>
    </>
  );
};

export default Total;
