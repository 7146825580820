import "./Desktop.css"
import SubscriptionForm from "./SubscriptionForm/SubscriptionForm";
import Payment from "./Payment";
import Reviews from "./Reviews";
import Faq from "./Faq";

const Desktop = ({
  handleSubmit,
  clientSecret,
  subscriptions,
  setSubscriptions,
  newName,
  setNewName,
  newEmail,
  setNewEmail,
  newAutopay,
  setNewAutopay,
  newDuration,
  setNewDuration,
  handleAddSubscription,
  currency,
  totalPrice,
  totalAdobePrice,
  isLoading,
  isPaymentDone,
  stripePromise,
  options,
  setClientSecret,
  prices,
  setIsPaymentDone,
  reviewsShown,
  setReviewsShown,
  images,
  faqShown,
  setFaqShown,
}) => {
  return (
    <div className='main-main'>
      <div className='main-main-main'>
        <SubscriptionForm
          handleSubmit={handleSubmit}
          clientSecret={clientSecret}
          setSubscriptions={setSubscriptions}
          newName={newName}
          setNewName={setNewName}
          newEmail={newEmail}
          setNewEmail={setNewEmail}
          newAutopay={newAutopay}
          setNewAutopay={setNewAutopay}
          newDuration={newDuration}
          setNewDuration={setNewDuration}
          currency={currency}
          totalPrice={totalPrice}
          handleAddSubscription={handleAddSubscription}
          totalAdobePrice={totalAdobePrice}
          isLoading={isLoading}
        />
        <Payment
          isPaymentDone={isPaymentDone}
          clientSecret={clientSecret}
          stripePromise={stripePromise}
          options={options}
          setClientSecret={setClientSecret}
          currency={currency}
          subscriptions={subscriptions}
          prices={prices}
          setIsPaymentDone={setIsPaymentDone}
          totalPrice={totalPrice}
          isLoading={isLoading}
        />
      </div>
      <Reviews reviewsShown={reviewsShown} setReviewsShown={setReviewsShown} images={images} />
      <Faq faqShown={faqShown} setFaqShown={setFaqShown} />
    </div>
  );
};

export default Desktop;
