import ReturnButtonSvg from "./ReturnButtonSvg";
const ReturnButton = ({ setClientSecret, setFormSubmittedAutoPay, returnToHome }) => {
  return (
    <button
      onClick={() => {
        (setClientSecret && setClientSecret(null)) ||
        (setFormSubmittedAutoPay && setFormSubmittedAutoPay(false)) ||
        (returnToHome && returnToHome())

    }}
      className="button gray"
      style={{ fontWeight: "bold" }}
    >
      <ReturnButtonSvg />
      Return
    </button>
  );
};

export default ReturnButton;
