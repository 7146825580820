import "./InfoLoginBar.css";
import { useEffect, useState } from "react"
import AvailableSubscription from "./AvailableSubscription/AvailableSubscription";
import Operationnal from "./Operationnal/Operationnal";
import LoginLogOutItems from "./LoginLogOutItems/LoginLogOutItems";
import toast from "react-hot-toast";
import axios from "axios";
import constants from "../../../constants";

const InfoLoginBar = ({ setDashBoard, dashBoard, triggerRerender }) => {
  const [availableSlots, setAvailableSlots] = useState(null);
  //const [totalSlots, setToalSlots] = useState(null)
  const [status, setStatus] = useState({
    status_text: 'Operationnal',
    status_color: 'green',
    status_description: 'Not operational'

  })
  useEffect(() => {
    const getSlotsData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.SERVER('production')}/organization/slot`
        })
        const websiteStatusRes = await axios({
          method: 'GET',
          url: `${constants.SERVER('production')}/website/status`
        })
        const responseData = response.data.data;
        setAvailableSlots(responseData.available_slot)
        console.log(websiteStatusRes.data.data);
        setStatus(websiteStatusRes.data.data)
        //setToalSlots(responseData.total_slot)
      } catch (error) {
        toast.error(error.response.data.message)
      }
    }

    getSlotsData()
  }, [])
  return (
    <>
      <div className="infoLoginBar">
        <AvailableSubscription availableSlots={availableSlots} />
        {/*<AvailableSubscription availableSlots={availableSlots} totalSlots={totalSlots} />*/}
        <Operationnal status={status} />
        <LoginLogOutItems setDashBoard={setDashBoard} dashBoard={dashBoard} triggerRerender={triggerRerender} />
      </div>
    </>
  );
};

export default InfoLoginBar;
