import "./LoginItems.css";
import LoadingButton from "../Loading/LoadingButton";
const LoginItems = ({
  expand,
  emailDone,
  setExpand,
  logOut,
  getInputValue,
  emailOpt,
  expandEmail,
  sendEmail,
  sendCode,
  handleLogout,
  showNoValidEmail,
  opt,
  goToDashboard,
  loadingEmail,
  loadingSendCode,
  loadingLogOut,
  isAuth,
  dashBoard,
}) => {
  return (
    <>
      <div
        className={`log ${expand ? "expand" : ""} ${emailDone ? "otp" : ""} ${
          logOut ? "logOut" : ""
        } ${isAuth ? "expand otp logOut" : ""}`}
        onClick={() => setExpand(true)}
      >
        Log in
      </div>
      <div
        className={`email ${expand ? "show" : ""} ${emailDone ? "otp" : ""} ${
          logOut ? "logOut" : ""
        } ${isAuth ? "show otp logOut" : ""}`}
      >
        <div
          className={`inside ${expand ? "show" : ""} ${emailDone ? "otp" : ""} ${
            logOut ? "logOut" : ""
          } ${isAuth ? "show otp logOut" : ""}`}
        >
          <input
            type="text"
            className={`${emailDone ? "sendCode" : ""}  `}
            pattern={`${emailDone && "[0-9]*"}`}
            maxLength={`${emailDone ? 6 : 999}`}
            value={getInputValue()}
            onChange={(e) => emailOpt(e)}
            placeholder={`${expandEmail ? "6 9 6 9 6 9" : "email"}`}
          />
          <button
            className={`default ${showNoValidEmail && !logOut && !isAuth ? "button-email" : ""} ${
              opt.length < 6 && emailDone ? "button-opt" : ""
            } ${opt.length === 6 && !logOut ? "button-opt-enabled" : ""} ${
              isAuth ? "button-dashBoard" : ""
            } ${dashBoard ? "button-dashBoard-active" : ""}`}
            onClick={() => {
              if (!emailDone && !isAuth) {
                sendEmail();
              } else if (isAuth) {
                goToDashboard();
              } else {
                sendCode();
              }
            }}
          >
            {!emailDone && !isAuth ? "Send code" : ""}
            {emailDone && !logOut ? "Verify" : ""}
            {logOut || isAuth ? "Dashboard" : ""}
          </button>
          <button
            className={`logOut-button ${isAuth ? "show" : ""}`}
            onClick={() => handleLogout()}
            style={{ display: `${isAuth ? "block" : "none"}` }}
          >
            {isAuth ? "Log out" : ""}
          </button>
        </div>
        <LoadingButton
          loadingEmail={loadingEmail}
          loadingSendCode={loadingSendCode}
          loadingLogOut={loadingLogOut}
        />
      </div>
    </>
  );
};

export default LoginItems;
