import "./Refund.css"
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import RefundSvg from "./RefundSvg";

function Refund() {
  return (
    <div
      className='refund-container'
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gap: 30,
      }}
    >
      <Link
        to='/'
        className='main-refund'
        style={{
          flexDirection: "row",
          textDecoration: "none",
          justifyContent: "flex-start",
          gap: 20,
          padding: 20,
          width: "fit-content",
          minWidth: "fit-content",
        }}
      >
        <RefundSvg />
        <h2 style={{ textDecoration: "none", color: "black" }}>
          Go back to home
        </h2>
      </Link>
      <div className='main-refund' style={{ marginTop: 20 }}>
        <h1>Refund policy</h1>
        <p
          style={{
            textAlign: "left",
            width: "fit-content",
            alignSelf: "flex-start",
          }}
        >
          We understand that sometimes circumstances change, and you may need to reconsider a purchase. 
          That's why we offer a hassle-free refund policy for recent orders.
        </p>
        <p
          style={{
            textAlign: "left",
            width: "fit-content",
            alignSelf: "flex-start",
          }}
        >
          You can receive a 95% refund on any order placed within the last 72 hours. 
            No need to provide a reason, simply request a refund and we'll process it promptly. 
            We retain 5% to cover processing and administrative fees.
        </p>
        <p
          style={{
            textAlign: "left",
            width: "fit-content",
            alignSelf: "flex-start",
          }}
        >
          After the initial 72-hour period, refunds are generally not available for our digital subscriptions and products. 
          These are designed to provide ongoing value and access, making refunds impractical after a certain point.
        </p>
        <p
          style={{
            textAlign: "left",
            width: "fit-content",
            alignSelf: "flex-start",
          }}
        >
         However, we recognize that exceptional situations can arise. 
        If you have a unique circumstance beyond the 72-hour window, please contact us. 
           We will review your request and consider exceptions on a case-by-case basis.
        </p>
           <p
          style={{
            textAlign: "left",
            width: "fit-content",
            alignSelf: "flex-start",
          }}
        >
         If you have an inquiry about anything, please contact us on Telegram (@cheapccnet) or by email (help@cheapcc.net).
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Refund;
