import "../../css/App.css";
import "../../css/Media.css";
import "./Main.css";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";
import Logo from "../../images/cheapcclogo.avif";
import { priceHelper } from "./helper";
import toast from "react-hot-toast";
import constants from "../../constants";
import axios from "axios";
import InfoLoginBar from "./InfoLoginBar/InfoLoginBar";
import { format } from "date-fns";
const STRIPE_PUBLIC_KEY = constants.STRIPE_PUBLIC_KEY;
const API_URL = constants.API_URL;
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
const {
  price: { _prices, _AdobePrices, _pricesDataEur, _pricesDataUs },
} = priceHelper;

function Main() {
  const [faqShown, setFaqShown] = useState(false);
  const [currency, setCurrency] = useState("€");
  const [prices] = useState(_prices);
  const [AdobePrices] = useState(_AdobePrices);
  const [name, setName] = useState("");
  const [isAutoPay, setIsAutoPay] = useState(false);
  const [email, setEmail] = useState(null);
  const [priceData, setpriceData] = useState(_pricesDataEur(currency));
  const [duration, setDuration] = useState(30);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [formSubmittedAutoPay, setFormSubmittedAutoPay] = useState(false);
  const [dashBoard, setDashBoard] = useState(false)
  const isMobile = useMediaQuery("(max-width: 668px)");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://extreme-ip-lookup.com/json/?key=uxJZ5TPQRYbhGqTVc6DI"
        );
        const data = await response.json();
        const country = data.countryCode;
        setCurrency(country === "US" ? "$" : "€");
        setpriceData(_pricesDataUs(country));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currency]);

  useEffect(() => {
    if (isMobile && isPaymentDone) {
      const { top } = mobileMain.current.getBoundingClientRect();
      window.scrollTo({
        top: top + document.documentElement.scrollTop - 15,
        behavior: "smooth",
      });
    }
  }, [isPaymentDone, isMobile]);

  const triggerRerender = () => {
    setName("")
    setIsAutoPay(false)
    setEmail(null)
    setIsLoading(false)
    setIsPaymentDone(false)
    setClientSecret(null)
    setIsHovered(false)
    setFormSubmittedAutoPay(false)
    setDashBoard(false)
  };
  const svgRef = useRef(null);
  const mobileMain = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const returnToHome = () => {
    if (isAutoPay) {
      setFormSubmittedAutoPay(false);
    }
    if (isAutoPay === false || isAutoPay === "false") {
      setClientSecret(null);
    }
    if (isMobile) {
      const { top } = mobileMain.current.getBoundingClientRect();
      window.scrollTo({
        top: top + document.documentElement.scrollTop - 15,
        behavior: "smooth",
      });
    }
  };

  const getSvgPosition = () => {
    if (svgRef.current) {
      const { x, y, width } = svgRef.current.getBoundingClientRect();
      return { top: y - 20, left: isMobile ? x - width - 50 : x - width + 50 };
    }
    return { top: 0, left: 0 };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const agent = navigator.userAgent;

      if (isAutoPay === false || isAutoPay === "false") {
        const res = await axios.post(
          API_URL + "/payment/create-payment-intent",
          {
            browserAgent: agent,
            amount: prices[duration],
            name: name,
            email: email,
            duration: duration,
            currency: currency,
            bulk: false,
            orders: null,
            purchase_date: format(new Date(), "yyyy-MM-dd"),
          },
          {
            withCredentials: true
          }
        );
        setClientSecret(res.data.clientSecret);
      }
      if (isAutoPay) {
        setFormSubmittedAutoPay(true);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#1c53de",
    },
  };

  const options =
    isAutoPay === false
      ? { clientSecret, appearance, business: "ASM" }
      : {
        mode: "setup",
        currency: currency === "$" ? "usd" : "eur",
        appearance,
        business: "ASM",
      };
  return (
    <div className="App">
      <div className="logo-div">
        <img
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          draggable={false}
          src={Logo}
          className="logo"
          alt="cheapcc-logo"
        />
      </div>
      <InfoLoginBar setDashBoard={setDashBoard} dashBoard={dashBoard} triggerRerender={triggerRerender} />
      {(isMobile === "false" || isMobile === false) && (
        <Desktop
          isHovered={isHovered}
          getSvgPosition={getSvgPosition}
          handleSubmit={handleSubmit}
          clientSecret={clientSecret}
          formSubmittedAutoPay={formSubmittedAutoPay}
          name={name}
          setName={setName}
          duration={duration}
          setDuration={setDuration}
          email={email}
          setEmail={setEmail}
          isAutoPay={isAutoPay}
          setIsAutoPay={setIsAutoPay}
          svgRef={svgRef}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          currency={currency}
          prices={prices}
          AdobePrices={AdobePrices}
          isLoading={isLoading}
          isPaymentDone={isPaymentDone}
          stripePromise={stripePromise}
          options={options}
          setClientSecret={setClientSecret}
          priceData={priceData}
          setIsPaymentDone={setIsPaymentDone}
          setFormSubmittedAutoPay={setFormSubmittedAutoPay}
          faqShown={faqShown}
          setFaqShown={setFaqShown}
          dashBoard={dashBoard}
        />
      )}
      {isMobile && (
        <Mobile
          mobileMain={mobileMain}
          isHovered={isHovered}
          getSvgPosition={getSvgPosition}
          handleSubmit={handleSubmit}
          clientSecret={clientSecret}
          formSubmittedAutoPay={formSubmittedAutoPay}
          name={name}
          setName={setName}
          duration={duration}
          setDuration={setDuration}
          email={email}
          setEmail={setEmail}
          isAutoPay={isAutoPay}
          setIsAutoPay={setIsAutoPay}
          svgRef={svgRef}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          currency={currency}
          prices={prices}
          AdobePrices={AdobePrices}
          isLoading={isLoading}
          isPaymentDone={isPaymentDone}
          stripePromise={stripePromise}
          options={options}
          priceData={priceData}
          setIsPaymentDone={setIsPaymentDone}
          returnToHome={returnToHome}
          dashBoard={dashBoard}
        />
      )}
    </div>
  );
}

export default Main;
