import "./ButtonPayNow.css"
import ButtonPayNowSvg from "./ButtonPayNowSvg";

const ButtonPayNow = ({
  autoPay,
  createSubscription,
  handlePaymentSubmit,
  isPaymentLoading,
  stripe,
}) => {
  return (
    <>
      <button
        onClick={autoPay ? createSubscription : handlePaymentSubmit}
        className='button pay-now'
        style={{
          cursor: "pointer",
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
          fontSize: "18px",
          marginTop: 30,
          padding: "10px 20px",
          alignSelf: "center",
          justifySelf: "center",
        }}
        disabled={isPaymentLoading || !stripe}
        target='_blank'
        rel='noreferrer noopener'
        type='submit'
      >
        <span>
          {isPaymentLoading ? (
            <div className='spinner' id='spinner'>
              <ButtonPayNowSvg />
            </div>
          ) : (
            "Pay now"
          )}
        </span>
      </button>
    </>
  );
};

export default ButtonPayNow;
