const Price = ({ currency, totalPrice, totalAdobePrice}) => {
  return (
    <>
      <div>
        <h1 style={{ textWrap: "nowrap", width: "fit-content" }}>
          <span style={{ fontSize: 24 }}>{currency}</span>{" "}
          {totalPrice.toFixed(2)}
        </h1>
        {totalPrice !== 0 && (
          <p style={{ color: "black" }}>
            You save{" "}
            <b>
              {currency}
              {(totalAdobePrice - totalPrice).toFixed(2)}
            </b>{" "}
            compared to classic prices.
          </p>
        )}
      </div>
    </>
  );
};

export default Price
