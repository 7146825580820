const PaymentSvg = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='154px' height='154px'>
    <g fill='none' stroke='#22AE73' stroke-width='2'>
      <circle
        cx='77'
        cy='77'
        r='72'
        style={{
          strokeDasharray: "480px, 480px",
          strokeDashOffset: 960,
        }}
      ></circle>
      <circle
        id='colored'
        fill='#22AE73'
        cx='77'
        cy='77'
        r='72'
        style={{
          strokeDasharray: "480px, 480px",
          strokeDashOffset: 960,
        }}
      ></circle>
      <polyline
        class='st0'
        stroke='#fff'
        stroke-width='10'
        points='43.5,77.8 63.7,97.9 112.2,49.4 '
        style={{
          strokeDasharray: "100px, 100px",
          strokeDashOffset: 200,
        }}
      />
    </g>
  </svg>
);

export default PaymentSvg;
