const Subscriptions = ({ subscriptions, setSubscriptions}) => {
    return <> 
    {subscriptions.map((subscription, index) => (
        <tr key={index}>
          <td>{subscription.name}</td>
          <td>{subscription.email}</td>
          <td><input type='checkbox' defaultChecked={subscription.autopay} onChange={() => (setSubscriptions([...subscriptions.slice(0, index), { name: subscription.name, email: subscription.email, duration: subscription.duration, autopay: !subscription.autopay }, ...subscriptions.slice(index + 1)]))} /></td>
          <td>{subscription.duration === '30' ? '1 month' : (subscription.duration === '365' ? '1 year' : (subscription.duration === '730' ? '2 years' : subscription.duration === '1095' ? '3 years' : '??'))}</td>
          <td style={{ cursor: 'pointer', backgroundColor: '#d43f3f', color: 'white', fontFamily: 'Arial', fontWeight: 'bold' }} onClick={() => (setSubscriptions([...subscriptions.slice(0, index), ...subscriptions.slice(index + 1)]))}>X</td>
        </tr>
      ))}
    </>
}

export default Subscriptions;