const Name = ({ name }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 15,
        }}
      >
        <p
          style={{
            textWrap: "nowrap",
            width: "fit-content",
            margin: 0,
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            color: "#30313D",
          }}
        >
          Name:
        </p>
        <p
          style={{
            textWrap: "nowrap",
            width: "fit-content",
            margin: 0,
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            color: "#30313D",
          }}
        >
          {name}
        </p>
      </div>
    </>
  );
};

export default Name;
