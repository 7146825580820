import './Success.css'
import React from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import Desktop from './Desktop';
import Mobile from './Mobile';

function Success() {
  const isMobile = useMediaQuery('(max-width: 668px)');
  return (
    <div className="App">
      <div className='logo-div'>
        <img style={{ display: 'flex', width: '100%', justifyContent: 'center' }} draggable={false} src='/images/cheapcc.png' className='logo' alt='cheapcc-logo' />
      </div>
      {(isMobile === 'false' || isMobile === false) && <Desktop />}
      {(isMobile) && <Mobile />}
    </div>
  );
}

export default Success;
