import "../../SubscriptionForm.css"
import PayButton from "./Button/PayButton";
import FullNameInput from "./Input/FullName";
import EmailInput from "./Input/Email";
import DurationSelect from "./Select/Duration";
import AutoPaySelect from "./Select/AutoPay";
import Price from "./Price";

const SubscriptionForm = ({
  handleSubmit,
  clientSecret,
  formSubmittedAutoPay,
  name,
  setName,
  duration,
  setDuration,
  email,
  setEmail,
  isAutoPay,
  setIsAutoPay,
  svgRef,
  handleMouseEnter,
  handleMouseLeave,
  currency,
  prices,
  AdobePrices,
  dashBoard,
  isLoading,
}) => {
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={`inputs-mobile ${
          !clientSecret && formSubmittedAutoPay === false && !dashBoard ? "shown" : ""
        }`}
      >
        {!clientSecret && formSubmittedAutoPay === false && !dashBoard && (
          <div className='inputs-subdiv-mobile'>
            <h1 style={{ marginBottom: 0, textAlign: "left" }}>
              Submit an application
            </h1>
            <p style={{ fontSize: 18, margin: 0, marginTop: 10 }}>
              Apply the Creative Cloud All Apps plan to your own Adobe account, and enjoy an instant delivery.
            </p>
            <div className='input-container-mobile'>
              <FullNameInput name={name} setName={setName} />
              <EmailInput email={email} setEmail={setEmail} />
              <DurationSelect duration={duration} setDuration={setDuration} />
              <AutoPaySelect
                svgRef={svgRef}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                isAutoPay={isAutoPay}
                setIsAutoPay={setIsAutoPay}
              />
            </div>
            <Price
              currency={currency}
              prices={prices}
              duration={duration}
              AdobePrices={AdobePrices}
            />
            <PayButton
              email={email}
              name={name}
              clientSecret={clientSecret}
              formSubmittedAutoPay={formSubmittedAutoPay}
              isLoading={isLoading}
            />
          </div>
        )}
      </form>
    </>
  );
};

export default SubscriptionForm;
