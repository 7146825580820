import "./SubscriptionForm.css"
import Subscriptions from "./Subscriptions";
import FullNameInput from "./Input/FullName";
import EmailInput from "./Input/Email";
import AutoPay from "./Input/AutoPay";
import DurationSelect from "./Select/Duration";
import AddSubbscriptionButton from "./Button/AddSubscription";
import Price from "./Price";
import PayButton from "../../../Main/Desktop/SubscriptionForm/Button/PayButton";

const SubscriptionForm = ({
  handleSubmit,
  clientSecret,
  subscriptions,
  setSubscriptions,
  newName,
  setNewName,
  newEmail,
  setNewEmail,
  newAutopay,
  setNewAutopay,
  newDuration,
  setNewDuration,
  currency,
  totalPrice,
  handleAddSubscription,
  totalAdobePrice,
  isLoading,
}) => {
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={`inputs ${!clientSecret ? "shown" : ""}`}
      >
        {!clientSecret && (
          <div className='inputs-subdiv'>
            <h1 style={{ marginBottom: 0, textAlign: "left" }}>
              Create a group order
            </h1>
            <p>
              Included in our subcription is: all Adobe applications (except
              Substance 3D), 80GB cloud storage, Firefly AI with 250 credits
              renewed every month, access to beta apps, and 2 active
              simultaneous sessions (with 2 mobile sessions as well). You can
              access your suscription from anywhere on Earth, no VPN is needed.
            </p>
            <div className='input-container'>
              <div className='input-div' style={{ flexDirection: "column" }}>
                <p
                  style={{
                    fontSize: 14,
                    color: "#a6a6a6",
                    display: "flex",
                    flexDirection: "row",
                    gap: 7,
                    alignItems: "center",
                    width: "fit-content",
                  }}
                >
                  <img
                    alt='info-icon'
                    draggable={false}
                    height={18}
                    src='/images/info.svg'
                  />
                  The first user of the list is the payer.
                </p>
                <table>
                  <thead>
                    <tr>
                      <th>Full name</th>
                      <th>E-mail address</th>
                      <th style={{ minWidth: 87 }}>Auto-pay</th>
                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Subscriptions
                      subscriptions={subscriptions}
                      setSubscriptions={setSubscriptions}
                    />
                    <tr className='input-tr'>
                      <td className='input-td' style={{ borderRightWidth: 2 }}>
                        <FullNameInput
                          newName={newName}
                          setNewName={setNewName}
                        />
                      </td>
                      <td className='input-td'>
                        <EmailInput
                          newEmail={newEmail}
                          setNewEmail={setNewEmail}
                        />
                      </td>
                      <td
                        className='input-td'
                        style={{ height: 40, border: 0 }}
                      >
                        <AutoPay
                          newAutopay={newAutopay}
                          setNewAutopay={setNewAutopay}
                        />
                      </td>
                      <td className='input-td'>
                        <DurationSelect
                          newDuration={newDuration}
                          setNewDuration={setNewDuration}
                        />
                      </td>
                      <td
                        style={{
                          padding: 0,
                          minWidth: 30,
                          backgroundColor: "#c2c9d1",
                        }}
                      >
                        <AddSubbscriptionButton
                          newDuration={newDuration}
                          newEmail={newEmail}
                          newName={newName}
                          handleAddSubscription={handleAddSubscription}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <Price
              currency={currency}
              totalPrice={totalPrice}
              totalAdobePrice={totalAdobePrice}
            />
            <PayButton
              subscriptions={subscriptions}
              clientSecret={clientSecret}
              isLoading={isLoading}
            />
          </div>
        )}
      </form>
    </>
  );
};

export default SubscriptionForm;
