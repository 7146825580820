import axios from "axios";
import axiosRetry from "axios-retry";
import { useEffect, useState } from "react";
import useAuthStore from "../../../../store/authStore";
import { SwapSpinner } from "react-spinners-kit";
import constants from "../../../../constants";

const PaymentDone = ({ isPaymentDone, isLoading, email }) => {
  const { auth, fetchAuthState } = useAuthStore((state) => state);
  const [state, setState] = useState({
    isAuthenticated: auth.isAuth,
    isAuthenticating: true,
  });
  useEffect(() => {
    const client = axios.create();
    axiosRetry(client, {
      retries: 3,
      retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 3000),
      retryCondition(error) {
        switch (error.response.status) {
          //retry only if status is 500 or 501 or 400
          case 500:
          case 501:
          case 400:
            return true;
          default:
            return false;
        }
      },
      onMaxRetryTimesExceeded: () => {
        setState({
          isAuthenticated: false,
          isAuthenticating: false,
        });
      },
    });
    //generate me an immediately invoked function
    (async () => {
      try {
        const res = await client.post(
          constants.API_URL + "/auth/authenticate",
          {
            email,
          },
          {
            withCredentials: true,
          }
        );
        if (res.status === 200) {
          fetchAuthState();
          setState({
            isAuthenticated: true,
            isAuthenticating: false,
          });
        }
      } catch (error) {}
    })();
  }, [email , fetchAuthState]);
  return (
    <div
      className={`payment-container-done ${
        isPaymentDone && isLoading === false ? "shown" : ""
      }`}
    >
      <div className="success-div">
        <div class="animation-ctn">
          <div class="icon icon--order-success svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="154px"
              height="154px"
            >
              <g fill="none" stroke="#22AE73" stroke-width="2">
                <circle
                  cx="77"
                  cy="77"
                  r="72"
                  style={{
                    strokeDasharray: "480px, 480px",
                    strokeDashOffset: 960,
                  }}
                ></circle>
                <circle
                  id="colored"
                  fill="#22AE73"
                  cx="77"
                  cy="77"
                  r="72"
                  style={{
                    strokeDasharray: "480px, 480px",
                    strokeDashOffset: 960,
                  }}
                ></circle>
                <polyline
                  class="st0"
                  stroke="#fff"
                  stroke-width="10"
                  points="43.5,77.8 63.7,97.9 112.2,49.4 "
                  style={{
                    strokeDasharray: "100px, 100px",
                    strokeDashOffset: 200,
                  }}
                />
              </g>
            </svg>
          </div>
        </div>
        {isPaymentDone && isLoading === false && (
          <>
            <h2 style={{ textAlign: "center", marginTop: 60 }}>
              Payment successful.
            </h2>
            <p style={{ textAlign: "center", marginTop: 60 }}>
              You should receive your invitation email within 24 hours.
            </p>
            <p style={{ textAlign: "center", marginTop: 10 }}>
              If any problem, contact us by email:
            </p>
            <h3 style={{ textAlign: "center", marginTop: 20 }}>
              help@cheapcc.net
            </h3>
          </>
        )}
      </div>
      {!state.isAuthenticated && state.isAuthenticating ? (
        <div className="d-account-creation-loading">
          <h4>We are creating your account hold on</h4>
          <SwapSpinner color="#10b981" size={30} />
        </div>
      ) : state.isAuthenticated && !state.isAuthenticating ? (
        <div className="d-account-created">
          <h4>
            Account created successfully you can now access it from your
            dashboard!
          </h4>
        </div>
      ) : (
        <div className="d-account-creation-loading">
          <h4>
            Instructions will be sent to your email, and you can view details in
            dasboard.
          </h4>
        </div>
      )}
    </div>
  );
};

export default PaymentDone;
