const AddSubbscriptionButton = ({
  newDuration,
  newEmail,
  newName,
  handleAddSubscription,
}) => (
  <button
    className='button'
    style={{
      padding: 0,
      borderRadius: 0,
      width: "100%",
      height: 40,
      fontSize: 22,
      fontWeight: "bold",
      fontFamily: "Arial",
    }}
    disabled={
      newDuration === "N/A" ||
      newEmail === "" ||
      newEmail.includes("@") === false ||
      newName === ""
    }
    disa
    onClick={handleAddSubscription}
  >
    +
  </button>
);

export default AddSubbscriptionButton;
