const DurationSelect = ({ newDuration, setNewDuration }) => {
  return (
    <>
      <select
        className='w-full'
        value={newDuration}
        onChange={(e) => setNewDuration(e.target.value)}
        defaultValue={"N/A"}
        style={{ width: "100%", border: "0", minWidth: "100px" }}
      >
        <option disabled value='N/A'>
          Select duration
        </option>
        <option value={30}>1 month</option>
        <option value={365}>1 year</option>
        <option value={730}>2 years</option>
        <option value={1095}>3 years</option>
      </select>
    </>
  );
};

export default DurationSelect;
