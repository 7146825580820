import '../AutoPayPopUp.css'
const AutoPayPopUp = ({ getSvgPosition }) => {
    return <>
    <div
            className='question-div'
            style={{
              position: "fixed",
              ...getSvgPosition(),
              zIndex: 999,
              width: "230px",
              backgroundColor: "black",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            If disabled, your subscription will be revoked at the end of the
            first period. If enabled, renewal will be automatic.<br></br>To
            cancel auto-pay, simply contact us.
          </div>
    </>
}

export default AutoPayPopUp