import '../../css/App.css'
import '../../css/Media.css'
import { loadStripe } from '@stripe/stripe-js';
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import Desktop from './Desktop/Desktop';
import Mobile from './Mobile/Mobile';
import Footer from '../Footer/Footer'
import Logo from '../../images/cheapcclogo.avif'
import Images from './Images';
import {priceHelper } from "../Main/helper"
const stripePromise = loadStripe('pk_live_51Q0mpPAvc7OJE1HgrXR7hUfqHQT6vYzKOFhUyY5SOnGmCWzqyDNCNL4ZazSdmLlW2ypzidtPFySZ1P0CiLbCx4aK002N51ezBu');
const { price: { _prices, _AdobePrices } } = priceHelper;

function Bulk() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [reviewsShown, setReviewsShown] = useState(false)
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newAutopay, setNewAutopay] = useState(false);
  const [newDuration, setNewDuration] = useState('N/A');
  const [faqShown, setFaqShown] = useState(false)
  const [currency, setCurrency] = useState('€')
  const [prices] = useState(_prices);
  const [AdobePrices] = useState(_AdobePrices);
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalAdobePrice, setTotalAdobePrice] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const isMobile = useMediaQuery('(max-width: 668px)');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://extreme-ip-lookup.com/json/?key=uxJZ5TPQRYbhGqTVc6DI');
        const data = await response.json();
        const country = data.countryCode;
        setCurrency(country === 'US' ? '$' : '€');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currency]);

  useEffect(() => {
    let total = 0;
    let totaladobe = 0
    subscriptions.map((subscription) => (
      total += prices[subscription.duration]
    ))
    subscriptions.map((subscription) => (
      totaladobe += AdobePrices[subscription.duration]
    ))

    setTotalPrice(total);
    setTotalAdobePrice(totaladobe)

  }, [subscriptions, AdobePrices, prices]);

  const handleAddSubscription = () => {
    setSubscriptions([
      ...subscriptions,
      { name: newName, email: newEmail, duration: newDuration, autopay: newAutopay },
    ]);
    setNewName('');
    setNewEmail('');
    setNewAutopay(false)
    setNewDuration('N/A');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {

      const agent = navigator.userAgent;
      const response = await fetch('/.netlify/functions/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          browserAgent: agent,
          amount: totalPrice,
          name: subscriptions[0].name,
          email: subscriptions[0].email,
          duration: 0,
          currency: currency,
          bulk: true,
          orders: subscriptions,
        }),
      })
      const data = response.json()
      setClientSecret(data.clientSecret)
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#1c53de'
    }
  };

  const options = { clientSecret, appearance, business: "ASM" }

  return (
    <div className="App">
      <div className='logo-div'>
        <img style={{ display: 'flex', width: '100%', justifyContent: 'center' }} draggable={false} src={Logo} className='logo' alt='cheapcc-logo' />
      </div>
      <div className='main'>
      {(isMobile===false || isMobile==='false')
        && <Desktop handleSubmit={handleSubmit} clientSecret={clientSecret} subscriptions={subscriptions} setSubscriptions={setSubscriptions} newName={newName} setNewName={setNewName} newEmail={newEmail} setNewEmail={setNewEmail} newAutopay={newAutopay} setNewAutopay={setNewAutopay} newDuration={newDuration} setNewDuration={setNewDuration} handleAddSubscription={handleAddSubscription} currency={currency} totalPrice={totalPrice} totalAdobePrice={totalAdobePrice} isLoading={isLoading} isPaymentDone={isPaymentDone} stripePromise={stripePromise} options={options} setClientSecret={setClientSecret} prices={prices} setIsPaymentDone={setIsPaymentDone} reviewsShown={reviewsShown} setReviewsShown={setReviewsShown} images={Images} faqShown={faqShown} setFaqShown={setFaqShown} />}
        {(isMobile)
        && <Mobile />}
        <Footer />
      </div>
    </div>
  );
}

export default Bulk;
