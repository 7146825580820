import "../Desktop/Payment/Payment.css";
import { Elements } from "@stripe/react-stripe-js";
import Wrapper from "../../Wrapper/Wrapper";
import ReturnButton from "../Desktop/Payment/Button/ReturnButton";
import PaymentDoneMobile from "./PaymentDoneMobile";

const Payment = ({
  isPaymentDone,
  clientSecret,
  formSubmittedAutoPay,
  isAutoPay,
  stripePromise,
  options,
  returnToHome,
  mobileMain,
  currency,
  prices,
  priceData,
  duration,
  name,
  email,
  setIsPaymentDone,
  dashBoard,
  isLoading,
}) => {
  return (
    <>
      <div
        className={`payment-container-mobile ${
          (isPaymentDone === false && clientSecret && !dashBoard) ||
          (formSubmittedAutoPay && isPaymentDone === false)
            ? "shown"
            : ""
        }`}
      >
        {isPaymentDone === false && clientSecret && isAutoPay === false && !dashBoard && (
          <Elements stripe={stripePromise} options={options}>
            <ReturnButton returnToHome={returnToHome} />
            <Wrapper
              mobileMain={
                mobileMain.current.getBoundingClientRect().top +
                document.documentElement.scrollTop
              }
              currency={currency}
              priceId={priceData[duration]}
              autoPay={isAutoPay}
              name={name}
              email={email}
              duration={duration}
              prices={prices}
              isPaymentDone={isPaymentDone}
              setIsPaymentDone={setIsPaymentDone}
              clientSecret={clientSecret}
              bulk={false}
              bulk_data={null}
            />
          </Elements>
        )}
        {isPaymentDone === false && isAutoPay && formSubmittedAutoPay && !dashBoard && (
          <Elements stripe={stripePromise} options={options}>
            <ReturnButton returnToHome={returnToHome} />
            <Wrapper
              mobileMain={
                mobileMain.current.getBoundingClientRect().top +
                document.documentElement.scrollTop
              }
              currency={currency}
              priceId={priceData[duration]}
              autoPay={isAutoPay}
              name={name}
              email={email}
              duration={duration}
              prices={prices}
              isPaymentDone={isPaymentDone}
              setIsPaymentDone={setIsPaymentDone}
              bulk={false}
              bulk_data={null}
            />
          </Elements>
        )}
      </div>
      {isPaymentDone && isLoading === false && !dashBoard && (
        <PaymentDoneMobile
          email={email}
          isLoading={isLoading}
          isPaymentDone={isPaymentDone}
        />
      )}
    </>
  );
};

export default Payment;
