import { create } from "zustand";
import constants from "../constants";
import axios from "axios";
const domainName = constants.SERVER("production")

const useAuthStore = create((set) => ({
  auth: {
    type: "",
    id: "",
    emal: "",
    isAuth: localStorage.getItem("isAuth") || false,
  },
  fetchAuthState: async () => {
    console.log('fetchAuthState')
    try {
      const response = await axios.get(domainName + "/auth/userinfo", {
        withCredentials: true,
      });
      if (response.status === 200) {
        set({
          auth: response.data.data,
        });
        localStorage.setItem("isAuth", true);
      }
    } catch (error) {
      localStorage.clear();
      set({
        auth: { id: "", emal: "", isAuth: false },
      });
      console.log(error);
    }
  },
  clearAuth: () => {
    localStorage.clear();
    set({
      auth: { id: "", emal: "", isAuth: false },
    });
  },
}));

export default useAuthStore;
