import { useEffect, useState } from "react";
import "./Dashboard.css";
import Collapsible from "react-collapsible";
import axios from "axios";
import constants from "../../constants";
import { getRemainigDays, getSubsDuration } from "../../utils";
import { format } from "date-fns";
import useAuthStore from "../../store/authStore";
import CancelSubscription from "./CancelSubscription/CancelSubscription";
import InfoLoginBar from "../Main/InfoLoginBar/InfoLoginBar";
import Logo from "../../images/cheapcclogo.avif";
import { useNavigate } from "react-router-dom";
import SwitchEmail from "./SwitchEmail/SwitchEmail";

function Dashboard() {
  const auth = useAuthStore((state) => state.auth);
  const navigate = useNavigate();
  return (
    <main className="App">
      <div className="logo-div">
        <img
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          draggable={false}
          src={Logo}
          className="logo"
          alt="cheapcc-logo"
        />
      </div>

      <InfoLoginBar />
      <section className="dashboard-container">
        <div className="dashboard-content-wrapper">
          <button onClick={() => navigate("/")} className="buttonNav gray">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-chevron-left"
            >
              <path d="m15 18-6-6 6-6" />
            </svg>
            Home
          </button>
          <div className="dashboar-hero-text-wrapper">
            <p className="dashboard-hero-text">Your orders</p>
            <span>{`(${auth?.email})`}</span>
          </div>
          <Table />
        </div>
      </section>
    </main>
  );
}

const Table = () => {
  const [orders, setOrders] = useState([]);
  async function fetchUserOrders() {
    try {
      const res = await axios.get(constants.API_URL + "/orders", {
        withCredentials: true,
      });
      const data = res.data?.data;
      setOrders(data || []);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchUserOrders();
  }, []);
  return (
    <>
      <TableHeader />
      <TableRow orders={orders} fetchUserOrders={fetchUserOrders} />
    </>
  );
};
const TableHeader = () => {
  return (
    <div className="tableHeader">
      <div className="tableCellWidthId">ID</div>
      <div className="tableCellWidthStatus">Status</div>
      <div className="tableCellWidthDuration">Duration</div>
      <div className="tableCellWidthAutopay">Autopay</div>
      <div className="tableCellWidthEmail">E-mail address</div>
      <div className="tableCellWidthAction">{""}</div>
    </div>
  );
};

const TableRow = ({ orders, fetchUserOrders }) => {
  return (
    <>
      {orders.map((data) => (
        <TableContentCollapse data={data} fetchUserOrders={fetchUserOrders} />
      ))}
    </>
  );
};
const TableContentCollapse = ({ data, fetchUserOrders }) => {
  const [open, setOpen] = useState(false);
  const [menus, setMenus] = useState({
    cancel_sub: false,
    switch_email: false,
  });
  const handleMenuToggle = (field, value) => {
    setMenus({
      ...menus,
      [field]: value,
    });
  };
  return (
    <Collapsible
      onTriggerOpening={() => setOpen(!open)}
      onTriggerClosing={() => setOpen(!open)}
      trigger={<TableRowContent data={data} open={open} />}
    >
      <CollapsibleItem
        title={"Activation e-mail address"}
        value={data?.console_email}
      />
      <CollapsibleItem
        title={"Days left"}
        value={getRemainigDays(data.expiration_date)}
      />
      <CollapsibleItem
        title={"Order date"}
        value={format(data?.purchase_date, "dd MMM yy")}
      />
      <CollapsibleItem
        title={"Assigned school/organization"}
        value={
          data.organization || (
            <span title="Order expired or cancelled!">NA</span>
          )
        }
      />
      <CollapsibleItem
        title={"Total paid"}
        value={`${data.currency === "usd" ? "$" : "€"} ${
          data.amount
        } ${data.currency.toString().toUpperCase()}`}
      />
      {(data.status === "active" || data.autopay) && (
        <div className="collapsible-action-container">
          {/* <button className="downloadBtn">Download receipt</button> */}
          {!menus.cancel_sub && !menus.switch_email && (
            <>
              {data.status === "active" && (
                <button
                  onClick={() => handleMenuToggle("switch_email", true)}
                  className="switchBtn"
                >
                  Switch email{" "}
                </button>
              )}
              {data.autopay && (
                <button
                  onClick={() => handleMenuToggle("cancel_sub", true)}
                  className="cancelBtn"
                >
                  Disable autopay{" "}
                </button>
              )}
            </>
          )}
          {menus.cancel_sub && (
            <CancelSubscription
              id={data.id}
              onCancel={() => handleMenuToggle("cancel_sub", false)}
              fetchUserOrders={fetchUserOrders}
            />
          )}
          {menus.switch_email && (
            <SwitchEmail
              id={data.id}
              onCancel={() => handleMenuToggle("switch_email", false)}
              fetchUserOrders={fetchUserOrders}
            />
          )}
        </div>
      )}
    </Collapsible>
  );
};
const TableRowContent = ({ data, open }) => {
  return (
    <div className="tableRow">
      <div className="tableRowFlex">
        <div className="tableCellWidthId"># {data.id}</div>
        <div className="tableCellWidthStatus">
          <DisplayStatus status={data.status} />
        </div>
        <div className="tableCellWidthDuration">
          {getSubsDuration(data.duration)}
        </div>
        <div className="tableCellWidthAutopay">
          <DisplayAutopay autopay={data.autopay} />
        </div>
        <div className="tableCellWidthEmail">{data?.console_email}</div>
        <div className={`tableCellWidthAction `}>
          <span
            style={{ display: "inline-block" }}
            className={` ${open ? "rotate-90" : "revert-90"}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#3b82f6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-chevron-right"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};

const DisplayStatus = ({ status }) => {
  return (
    <p className={`displayStatus`}>
      <span
        className={`
          statusCircle ${
            status === "active"
              ? "green"
              : status === "cancelled"
              ? "red"
              : "black"
          }
          `}
      />
      {status}
    </p>
  );
};
const DisplayAutopay = ({ autopay }) => {
  return (
    <p className={`displayStatus`}>
      <span
        className={`
          statusCircle ${autopay ? "green" : "red"}
          `}
      />
      {autopay ? "On" : "Off"}
    </p>
  );
};

const CollapsibleItem = ({ title, value }) => {
  return (
    <>
      <div className="collapse-content">
        <div className="collapse-content item">
          <p style={{ fontWeight: 600 }}>{title}</p>
          <p>{value}</p>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
