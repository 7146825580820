const FullNameInput = ({ newName, setNewName}) => (
  <input
    type='text'
    className='w-full'
    placeholder='Name'
    value={newName}
    onChange={(e) => setNewName(e.target.value)}
  />
);

export default FullNameInput;
