import "./AvailableSubscription.css"
import LoadingAva from "./LoadingAva"
const AvailableSubscription = ({ availableSlots }) => {
    return <>
    <div className='availableSubscription'>
            Remaining slots { availableSlots ? <span> {availableSlots} </span> : <span className="loadingAva"> <LoadingAva /></span>}
        </div>
    </>
}

export default AvailableSubscription
