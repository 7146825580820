import AutoPaySvg from "./AutoPaySvg"

const AutoPaySelect = ( { svgRef, handleMouseEnter, handleMouseLeave, isAutoPay, setIsAutoPay}) => {
    return <>
    <div className='input-div-mobile'>
                <label
                  style={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    fontSize: 18,
                  }}
                >
                  Enable auto-pay:
                  <AutoPaySvg svgRef={svgRef} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} />
                </label>
                <select
                  style={{ fontSize: 18, backgroundColor: "white" }}
                  value={isAutoPay}
                  onChange={(e) =>
                    setIsAutoPay(
                      e.target.value === "true" || e.target.value === true
                    )
                  }
                >
                  <option value={true}>Yes (free)</option>
                  <option value={false}>No, thanks</option>
                </select>
              </div>
    </>
}

export default AutoPaySelect;