import "./Reviews.css"
import Slider from "../../Slider/Slider"
import ReviewSvg from "./ReviewSvg"

const Reviews = ({ reviewsShown, setReviewsShown, images}) => {
    return <>
    <div className={`reviews ${reviewsShown ? "shown" : ""}`}>
        <div
          onClick={() => setReviewsShown(!reviewsShown)}
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: reviewsShown ? "flex-start" : "center",
            justifyContent: "space-between",
          }}
        >
          <h2
            style={{ fontSize: 40, fontWeight: 300 }}
            className={`reviews-title ${reviewsShown ? "withMargin" : ""}`}
          >
            Clients satisfaction
          </h2>
          <ReviewSvg reviewsShown={reviewsShown} />
        </div>
        {reviewsShown && <Slider imageUrls={images} />}
      </div>
    </>
}

export default Reviews