const FullNameInput = ({ name, setName }) => {
  return (
    <>
      <div className='input-div-mobile'>
        <label style={{ textAlign: "left", fontSize: 18 }}>Full name:</label>
        <div className='input-wrapper-mobile'>
          <input
            style={{ width: "-webkit-fill-available", fontSize: 18 }}
            value={name}
            spellCheck={false}
            required
            onChange={(e) => setName(e.target.value)}
            placeholder='James Bond'
            type='name'
          />
        </div>
      </div>
    </>
  );
};

export default FullNameInput;
