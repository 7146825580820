import "./Payment.css";
import { Elements } from "@stripe/react-stripe-js";
import Wrapper from "../../../Wrapper/Wrapper";
import ReturnButton from "./Button/ReturnButton";
import PaymentDone from "./PaymentDone";

const Payment = ({
  clientSecret,
  formSubmittedAutoPay,
  name,
  duration,
  email,
  isAutoPay,
  currency,
  prices,
  isLoading,
  isPaymentDone,
  stripePromise,
  options,
  setClientSecret,
  priceData,
  setIsPaymentDone,
  setFormSubmittedAutoPay,
  dashBoard
}) => {
  return (
    <>
    {console.log('dashBoard', dashBoard)}
      <div
        className={`payment-container ${
          (isPaymentDone === false && clientSecret && !dashBoard) ||
          (formSubmittedAutoPay && isPaymentDone === false && !dashBoard)
            ? "shown"
            : ""
        }`}
      >
        {isPaymentDone === false && clientSecret && isAutoPay === false && !dashBoard && (
          <Elements stripe={stripePromise} options={options}>
            <ReturnButton setClientSecret={setClientSecret} />
            <Wrapper
              currency={currency}
              priceId={priceData[duration]}
              autoPay={isAutoPay}
              name={name}
              email={email}
              duration={duration}
              prices={prices}
              isPaymentDone={isPaymentDone}
              setIsPaymentDone={setIsPaymentDone}
              clientSecret={clientSecret}
              bulk={false}
              bulk_data={null}
            />
          </Elements>
        )}
        {isPaymentDone === false && isAutoPay && formSubmittedAutoPay && !dashBoard && (
          <Elements stripe={stripePromise} options={options}>
            <ReturnButton setFormSubmittedAutoPay={setFormSubmittedAutoPay} />
            <Wrapper
              currency={currency}
              priceId={priceData[duration]}
              autoPay={isAutoPay}
              name={name}
              email={email}
              duration={duration}
              prices={prices}
              isPaymentDone={isPaymentDone}
              setIsPaymentDone={setIsPaymentDone}
              bulk={false}
              bulk_data={null}
            />
          </Elements>
        )}
      </div>
      {isPaymentDone && isLoading === false && !dashBoard && (
        <PaymentDone
          email={email}
          isLoading={isLoading}
          isPaymentDone={isPaymentDone}
        />
      )}
    </>
  );
};

export default Payment;
