import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

async function enableMocking() {
  if(process.env.REACT_APP_MOCK_SERVER_OFF === true) {
    return
  }

  const { worker } = await import('./mocks/browser')

  return worker.start()
}

const root = ReactDOM.createRoot(document.getElementById("root"));
enableMocking().then(() => {
  root.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>
  );
})

reportWebVitals();
