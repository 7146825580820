import AutoPayPopUp from "./AutoPayPopUp";
import SubscriptionForm from "./SubscriptionForm/SubscriptionForm";
import Payment from "./Payment/Payment";
import Faq from "./Faq";
import Footer from "../../Footer/Footer";
const Desktop = ({
  isHovered,
  getSvgPosition,
  handleSubmit,
  clientSecret,
  formSubmittedAutoPay,
  name,
  setName,
  duration,
  setDuration,
  email,
  setEmail,
  isAutoPay,
  setIsAutoPay,
  svgRef,
  handleMouseEnter,
  handleMouseLeave,
  currency,
  prices,
  AdobePrices,
  isLoading,
  isPaymentDone,
  stripePromise,
  options,
  setClientSecret,
  priceData,
  setIsPaymentDone,
  setFormSubmittedAutoPay,
  faqShown,
  setFaqShown,
  dashBoard
}) => {
  return (
    <div className="main">
      {isHovered && <AutoPayPopUp getSvgPosition={getSvgPosition} />}
      <div className="main-main">
        <div className="main-main-main">
          <SubscriptionForm
            handleSubmit={handleSubmit}
            clientSecret={clientSecret}
            formSubmittedAutoPay={formSubmittedAutoPay}
            name={name}
            setName={setName}
            duration={duration}
            setDuration={setDuration}
            email={email}
            setEmail={setEmail}
            isAutoPay={isAutoPay}
            setIsAutoPay={setIsAutoPay}
            svgRef={svgRef}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            currency={currency}
            prices={prices}
            AdobePrices={AdobePrices}
            dashBoard={dashBoard}
            isLoading={isLoading}
          />
          <Payment
            clientSecret={clientSecret}
            formSubmittedAutoPay={formSubmittedAutoPay}
            name={name}
            duration={duration}
            email={email}
            isAutoPay={isAutoPay}
            currency={currency}
            prices={prices}
            isLoading={isLoading}
            isPaymentDone={isPaymentDone}
            stripePromise={stripePromise}
            options={options}
            setClientSecret={setClientSecret}
            priceData={priceData}
            setIsPaymentDone={setIsPaymentDone}
            setFormSubmittedAutoPay={setFormSubmittedAutoPay}
            dashBoard={dashBoard}
          />
        </div>

        <Faq faqShown={faqShown} setFaqShown={setFaqShown} />
      </div>
      <Footer />
    </div>
  );
};

export default Desktop;
