const Images = () => {
  return [
    "https://i.ibb.co/Tbt9wzx/Capture-d-e-cran-2024-08-05-a-15-49-46.png",
    "https://i.ibb.co/Yy74tBm/Capture-d-e-cran-2024-08-31-a-17-44-35.png",
    "https://i.ibb.co/CtH9wDR/Capture-d-e-cran-2024-08-31-a-17-45-07.png",
    "https://i.ibb.co/5G8Wm76/Capture-d-e-cran-2024-08-31-a-17-47-50.png",
    "https://i.ibb.co/VQNLn9v/Capture-d-e-cran-2024-08-31-a-17-48-04.png",
    "https://i.ibb.co/MGKkpfb/Capture-d-e-cran-2024-08-31-a-17-48-15.png",
    "https://i.ibb.co/Jmy2Fr1/Capture-d-e-cran-2024-08-31-a-17-49-35.png",
    "https://i.ibb.co/xChyrJV/Capture-d-e-cran-2024-09-12-a-13-58-44.png",
    "https://i.ibb.co/5B6k3Tb/Capture-d-e-cran-2024-09-12-a-14-03-35.png",
    "https://i.ibb.co/0XXGj74/Capture-d-e-cran-2024-09-19-a-18-33-12.png",
    "https://i.ibb.co/rMpyqxc/Capture-d-e-cran-2024-09-19-a-18-55-27.png",
    "https://i.ibb.co/fvm6N3C/Capture-d-e-cran-2024-09-20-a-23-04-19.png",
    "https://i.ibb.co/jw6qP12/Capture-d-e-cran-2024-10-11-a-18-13-11.png",
    "https://i.ibb.co/JBfhfsT/Capture-d-e-cran-2024-10-11-a-19-25-05.png",
    "https://i.ibb.co/NgqPWvC/Capture-d-e-cran-2024-10-13-a-16-16-40.png",
    "https://i.ibb.co/68RHwHm/Capture-d-e-cran-2024-10-13-a-16-30-02.png",
    "https://i.ibb.co/tQVswsd/Capture-d-e-cran-2024-10-13-a-16-35-26.png",
    "https://i.ibb.co/pnnRL29/Capture-d-e-cran-2024-10-13-a-16-36-11.png",
    "https://i.ibb.co/qR9rx4X/Capture-d-e-cran-2024-10-13-a-16-37-34.png",
    "https://i.ibb.co/znqR7qW/Capture-d-e-cran-2024-10-13-a-16-39-24.png",
    "https://i.ibb.co/TRPF5TX/Capture-d-e-cran-2024-10-13-a-16-41-17.png",
    "https://i.ibb.co/HPDcCf7/Capture-d-e-cran-2024-10-13-a-16-42-31.png",
    "https://i.ibb.co/5L4q0Y0/Capture-d-e-cran-2024-10-13-a-16-43-47.png",
    "https://i.ibb.co/ysRSFwb/Capture-d-e-cran-2024-10-13-a-17-52-56.png",
    "https://i.ibb.co/41BrsLz/Capture-d-e-cran-2024-10-14-a-19-17-36.png",
  ];
};
export default Images
