import "./Login.css";
import { useState } from "react";
import constants from "../../../../constants";
import axios from "axios";
import LoginItems from "./LoginItems/LoginItems";
import useAuthStore from "../../../../store/authStore";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const domainName = constants.SERVER("production");

const LoginLogOutItems = ({ setDashBoard, dashBoard, triggerRerender }) => {
  const clearAuth = useAuthStore((state) => state.clearAuth);
  const auth = useAuthStore((state) => state.auth);
  const [email, setEmail] = useState("");
  const [showNoValidEmail, setShowNoValidEmail] = useState("Invalid email address");
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const [loadingLogOut, setLoadingLogOut] = useState(false);
  const [expand, setExpand] = useState(false);
  const [expandEmail, setEpandEmail] = useState(false)
  const [logOut, setLogOut] = useState(false);
  const [opt, setOpt] = useState("");
  const [emailToOpt, setEmailToOpt] = useState("")
  const [emailDone, setEmailDone] = useState(false);
  const fetchAuthState = useAuthStore((state) => state.fetchAuthState);
  const navigate = useNavigate();
  const goToDashboard = () => {
    navigate('/dashboard')
  }
  const emailOpt = (e) => {
    e.preventDefault();
    if (emailDone) {
      const result = e.target.validity.valid ? e.target.value : opt;
      setOpt(result);
    } else {
      const emailValue = e.target.value.replace(/\s/g, "");
      setEmail(emailValue);
      setEmailToOpt(emailValue)
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailValue)) {
        console.log('email invalid', opt.length)
        setShowNoValidEmail("Invalid email address");
      } else {
        console.log('email valid')
        setShowNoValidEmail("");
      }
    }
  };
  const getInputValue = () => {
    return email ? email : opt;
  };
  const sendEmail = async () => {
    setLoadingEmail(true);
    console.log('sendEmail')
    try {
      await axios({
        method: "POST",
        url: `${domainName}/auth/getotp`,
        data: { email },
      });
      setEpandEmail(!expandEmail)
      setEmailDone(true)
      setEmail('')
      setShowNoValidEmail("Invalid email address")
    } catch (error) {
      toast.error(error.response.data.message)
    } finally {
      setLoadingEmail(false);
    }
  };

  const sendCode = async () => {
    setLoadingSendCode(true)
    try {
      await axios({
        method: "POST",
        withCredentials: true,
        url: `${domainName}/auth/verify`,
        data: { email: emailToOpt, otp: opt },
      });
      fetchAuthState()
      setLogOut(!logOut)
      setEmailToOpt('')
    } catch (error) {
      toast.error(error.response.data.message)
    } finally {
      setLoadingSendCode(false);
    }
  };

  async function handleLogout() {
    setLoadingLogOut(true)
    try {
      const res = await axios.get(domainName + "/auth/logout", {
        withCredentials: true,
      });
      if (res.status === 200) {
        clearAuth();
        setExpand(false)
        setEpandEmail(false)
        setLogOut(false)
        setOpt('')
        setEmailToOpt('')
        setEmailDone(false)
        setShowNoValidEmail('Invalid email address')
        setEmail('')
        setDashBoard(false)
        triggerRerender()
      }
    } catch (error) {
      toast.error(error.response.data.message)
    } finally {
      setLoadingLogOut(false)
    }
  }
  return (
    <>
      <div className={`login`}>
        <LoginItems
          expand={expand}
          setExpand={setExpand}
          emailDone={emailDone}
          logOut={logOut}
          getInputValue={getInputValue}
          emailOpt={emailOpt}
          expandEmail={expandEmail}
          sendEmail={sendEmail}
          sendCode={sendCode}
          handleLogout={handleLogout}
          showNoValidEmail={showNoValidEmail}
          opt={opt}
          loadingEmail={loadingEmail}
          loadingSendCode={loadingSendCode}
          loadingLogOut={loadingLogOut}
          goToDashboard={goToDashboard}
          isAuth={auth.isAuth}
          dashBoard={dashBoard}
        />
      </div>
    </>
  );
};

export default LoginLogOutItems;
