import AutoPayPopUp from "./AutoPayPopUp";
import SubscriptionForm from "./SubscriptionForm/SubscriptionForm";
import Payment from "./Payment";
import Faq from "./Faq";
import Footer from "../../Footer/Footer";

const Mobile = ({
  mobileMain,
  isHovered,
  getSvgPosition,
  handleSubmit,
  clientSecret,
  formSubmittedAutoPay,
  name,
  setName,
  duration,
  setDuration,
  email,
  setEmail,
  isAutoPay,
  setIsAutoPay,
  svgRef,
  handleMouseEnter,
  handleMouseLeave,
  currency,
  prices,
  AdobePrices,
  isLoading,
  isPaymentDone,
  stripePromise,
  options,
  priceData,
  setIsPaymentDone,
  dashBoard,
  returnToHome,
}) => {
  return (
    <div className='mobile-main'>
      <div className='mobile-main-main' ref={mobileMain}>
        {isHovered && <AutoPayPopUp getSvgPosition={getSvgPosition} />}
        <SubscriptionForm
          handleSubmit={handleSubmit}
          clientSecret={clientSecret}
          formSubmittedAutoPay={formSubmittedAutoPay}
          name={name}
          setName={setName}
          duration={duration}
          setDuration={setDuration}
          email={email}
          setEmail={setEmail}
          isAutoPay={isAutoPay}
          setIsAutoPay={setIsAutoPay}
          svgRef={svgRef}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          currency={currency}
          prices={prices}
          AdobePrices={AdobePrices}
          dashBoard={dashBoard}
          isLoading={isLoading}
        />
        <Payment
          isPaymentDone={isPaymentDone}
          clientSecret={clientSecret}
          formSubmittedAutoPay={formSubmittedAutoPay}
          isAutoPay={isAutoPay}
          stripePromise={stripePromise}
          options={options}
          returnToHome={returnToHome}
          mobileMain={mobileMain}
          currency={currency}
          prices={prices}
          priceData={priceData}
          duration={duration}
          name={name}
          email={email}
          setIsPaymentDone={setIsPaymentDone}
          isLoading={isLoading}
          dashBoard={dashBoard}
        />
      </div>

      <Faq />
      <Footer
        style={{ width: "calc(100% - 100px)", marginTop: 20, marginBottom: 8 }}
        mobile={true}
      />
    </div>
  );
};

export default Mobile;
