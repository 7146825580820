import axios from "axios";
import axiosRetry from "axios-retry";
import { useEffect, useState } from "react";

import { SwapSpinner } from "react-spinners-kit";
import useAuthStore from "../../../store/authStore";
import constants from "../../../constants";

const PaymentDoneMobile = ({ isPaymentDone, isLoading, email }) => {
  const { auth, fetchAuthState } = useAuthStore((state) => state);
  const [isAuth] = useState(auth.isAuth);
  const [state, setState] = useState({
    isAuthenticated: false,
    isAuthenticating: true,
  });
  useEffect(() => {
    const client = axios.create();
    axiosRetry(client, {
      retries: 3,
      retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 3000),
      retryCondition(error) {
        switch (error.response.status) {
          //retry only if status is 500 or 501
          case 500:
          case 501:
          case 400:
            return true;
          default:
            return false;
        }
      },
      onMaxRetryTimesExceeded: () => {
        setState({
          isAuthenticated: false,
          isAuthenticating: false,
        });
      },
    });
    //generate me an immediately invoked function
    (async () => {
      try {
        const res = await client.post(
          constants.API_URL + "/auth/authenticate",
          {
            email,
          },
          {
            withCredentials: true,
          }
        );
        if (res.status === 200) {
          fetchAuthState();
          setState({
            isAuthenticated: true,
            isAuthenticating: false,
          });
        }
      } catch (error) {}
    })();
  }, [fetchAuthState, email]);
  return (
    <div
      className={`payment-container-done-mobile ${
        isPaymentDone && isLoading === false ? "shown" : ""
      }`}
    >
      <div className="success-div">
        <div class="animation-ctn">
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            {isPaymentDone && !isLoading && (
              <img
                src="/images/tick.svg"
                alt="tick"
                style={{ height: 80, alignSelf: "center" }}
              />
            )}
          </div>
        </div>
        {isPaymentDone && isLoading === false && (
          <>
            <h2 style={{ textAlign: "center", marginTop: 60 }}>
              Payment successful.
            </h2>
            <p style={{ textAlign: "center", marginTop: 60 }}>
              You should receive your invitation email within 24 hours.
            </p>
            <p style={{ textAlign: "center", marginTop: 10 }}>
              If any problem, contact us by email:
            </p>
            <h3 style={{ textAlign: "center", marginTop: 20 }}>
              help@cheapcc.net
            </h3>
          </>
        )}
      </div>
      {!isAuth && (
        <>
          {!state.isAuthenticated && state.isAuthenticating ? (
            <div className="d-account-creation-loading">
              <h4>We are creating your account hold on</h4>
              <SwapSpinner color="#10b981" size={30} />
            </div>
          ) : state.isAuthenticated && !state.isAuthenticating ? (
            <div className="d-account-created">
              <h4>
                Account created successfully you can now access it from your
                dashboard!
              </h4>
            </div>
          ) : (
            <div className="d-account-creation-loading">
              <h4>Instructions will be sent to your email.</h4>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentDoneMobile;
