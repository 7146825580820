import "./PrivacyNotice.css"
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import PrivacyNoticeSvg from "./PrivacyNoticeSvg";

function PrivacyNotice() {
  return (
    <div
      className='refund-container'
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gap: 30,
        paddingBottom: 100,
      }}
    >
      <Link
        to='/'
        className='main-refund'
        style={{
          flexDirection: "row",
          textDecoration: "none",
          justifyContent: "flex-start",
          gap: 20,
          padding: 20,
          width: "fit-content",
          minWidth: "fit-content",
        }}
      >
       <PrivacyNoticeSvg />
        <h2 style={{ textDecoration: "none", color: "black" }}>
          Go back to home
        </h2>
      </Link>
      <div
        className='main-refund'
        style={{ marginTop: 20, alignItems: "flex-start" }}
      >
        <h1>Privacy Notice</h1>
        <p>
          <strong>Effective since: 17/09/2024</strong>
        </p>
        <p>
          Welcome to cheapcc.net. We are committed to protecting your privacy
          and ensuring you have a positive experience on our website. This
          Privacy Notice outlines our practices regarding the collection, use,
          and protection of your data, in full compliance with European data
          protection laws, including the General Data Protection Regulation
          (GDPR).
        </p>

        <h2>1. Data Collection</h2>
        <p>
          At cheapcc.net, we do not collect any personal data from our users,
          except for the necessary information required from our customers to
          maintain active subscriptions and ensure the proper functioning of our
          services. This includes:
        </p>
        <ul>
          <li>
            <strong>Subscription Data:</strong> We collect information related
            to your subscription to manage and fulfill our service obligations.
            This data is limited to what is necessary for the operation and
            management of your active subscriptions.
          </li>
        </ul>

        <h2>2. Analytics</h2>
        <p>
          To improve our website and services, we collect anonymized data for
          statistical purposes. This data helps us understand how our users
          interact with our site and leads to better service optimization.
          Here’s how we handle this data:
        </p>
        <ul>
          <li>
            <strong>No Personal Data:</strong> We ensure that no personal data
            is collected through our analytics.
          </li>
          <li>
            <strong>No Cookies:</strong> Our analytics do not use cookies.
          </li>
          <li>
            <strong>Anonymization:</strong> All data collected for analytics is
            fully anonymized to prevent any association with individual users.
          </li>
        </ul>

        <h2>3. Use of Collected Data</h2>
        <p>The data we collect is solely used for the following purposes:</p>
        <ul>
          <li>
            <strong>Subscription Management:</strong> To manage and maintain
            your subscription, ensuring you have continuous access to our
            services.
          </li>
          <li>
            <strong>Service Improvement:</strong> To analyze usage patterns and
            make improvements to our services and user interface.
          </li>
        </ul>

        <h2>4. Compliance with European Data Protection Laws</h2>
        <p>
          Our data collection and processing practices are designed to be in
          strict compliance with European data protection laws, including:
        </p>
        <ul>
          <li>
            <strong>General Data Protection Regulation (GDPR):</strong> We
            adhere to the GDPR’s principles regarding the processing of personal
            data, such as lawfulness, fairness, transparency, purpose
            limitation, data minimization, accuracy, storage limitation,
            integrity, and confidentiality.
          </li>
          <li>
            <strong>Data Protection Rights:</strong> We ensure that all users
            have the right to access, correct, delete, restrict, or object to
            the processing of their personal data, as well as the right to data
            portability.
          </li>
        </ul>

        <h2>5. Data Protection</h2>
        <p>
          We implement robust security measures to protect the data we collect.
          These measures are designed to prevent unauthorized access,
          alteration, and misuse of user information.
        </p>

        <h2>6. Changes to This Privacy Notice</h2>
        <p>
          We may update this Privacy Notice from time to time. Any changes will
          be posted on this page with an updated revision date.
        </p>

        <h2>7. Contact Us</h2>
        <p>
          If you have any questions or concerns about our privacy practices,
          please contact us at privacy[at]cheapcc.net.
        </p>

        <p>
          By using our services, you acknowledge that you have read and
          understood this privacy notice.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyNotice;
