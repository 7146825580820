import { differenceInDays } from "date-fns";

export const getSubsDuration = (duration) => {
    switch (duration) {
        case 30:
            return "1 month";
        case 365:
            return "1 year";
        case 730:
            return "2 years";
        case 1095:
            return "3 years";
        default:
            return "";
    }
}

export const getRemainigDays = (date) => {
    const diff = differenceInDays(new Date(date), new Date());
    return diff < 0 ? 0 : diff
}

export function Sleep() {
    return new Promise((resolve) => {
        setTimeout(resolve, 1000);
    });
}
