import React from "react";
import "./CancelSubscription.css";
import Spinner from "../../Common/Spinner";
import toast from "react-hot-toast";
import constants from "../../../constants";
import axios from "axios";
import { Sleep } from "../../../utils";
export default function CancelSubscription({ id, onCancel, fetchUserOrders }) {
  const [loading, setLoading] = React.useState(false);
  const handleCancellation = async () => {
    try {
      setLoading(true);
      const res = await axios.patch(
        constants.API_URL + "/payment/cancel-subscription/" + id,
        {},
        {
          withCredentials: true,
        }
      );
      if (res.status === 200) {
        toast.success("Subscription cancelled successfully!");
        fetchUserOrders();
        await Sleep(800);
        onCancel();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <p>
        Canceling will end your subscription and any associated benefits. Once
        canceled, your subscription will not renew. Are you sure you want to
        continue?
      </p>
      <div className="cancel-btn-container">
        {loading ? (
          <button className="downloadBtn" style={{ alignItems: "center" }}>
            Processing
            <Spinner height={17} width={17} color={"#64748b"} />
          </button>
        ) : (
          <>
            <button className="downloadBtn" onClick={onCancel}>
              Cancel
            </button>
            <button className="cancelBtn" onClick={handleCancellation}>
              Proceed
            </button>
          </>
        )}
      </div>
    </div>
  );
}
