import "../Faq.css";
const Faq = ({ faqShown, setFaqShown }) => {
  return (
    <>
      <div className='faqContainer'>
        <div
          onClick={() => setFaqShown(!faqShown)}
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            width: "calc(700px - 72px)",
            alignItems: faqShown ? "flex-start" : "center",
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ fontSize: 40, fontWeight: 300 }}>More details</h2>
          <svg
            style={{
              transition: "0.5s",
              transform: faqShown ? "rotate(90deg)" : "",
            }}
            fill='#000000'
            height='35px'
            width='35px'
            version='1.1'
            id='Layer_1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 330 330'
          >
            <path
              id='XMLID_222_'
              d='M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
            c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
            C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
            C255,161.018,253.42,157.202,250.606,154.389z'
            />
          </svg>
        </div>
        <div className={`faq-expand ${faqShown ? "shown" : ""}`}>
          <div className='faq-expand__content'>
            <h3>{"What is CheapCC.net?"}</h3>
            <p>
              {
                "CheapCC.net is a site where you can submit a request for the use of unused Adobe licenses by organizations/schools verified by Adobe."
              }
            </p>
            <h3>{"Where does the subscription come from?"}</h3>
            <p>
              {
                "We have a partnership with many organizations/schools around the world that have unused Adobe licenses and are happy to help people in need."
              }
            </p>
            <p>
              {
                "Your Adobe subscription is applied to your own Adobe account via an e-mail invitation from one of these schools."
              }
            </p>
            <h3>{"Why can't I place an order?"}</h3>
            <p>
              {
                "Orders may be unavailable if all subscription slots are taken. We publicly display slot availability, and once a spot is purchased, it's locked for the duration paid. For further assistance, contact our support team."
              }
            </p>
            <h3>
              {
                "How can I be sure that my 3-year subscription will really last 3 years?"
              }
            </h3>
            <p>
              {
                "Legal agreements bind CheapCC and its partner organizations. Once your application has been approved, you will receive a letter from them certifying access to your Adobe license for the duration you have selected."
              }
            </p>
            <h3>{"Does anyone has access to my content?"}</h3>
            <p>
              {
                "We DO NOT have access to your content created in the Adobe applications. Only Adobe has potential access, particularly when using online features, such as generative AI. Here's an article about the recent updates to Adobe's terms of use on their access to user content:"
              }
            </p>
            {
              <a href='https://blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use'>
                blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use
              </a>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;