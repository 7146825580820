import "../Main/Desktop/Payment/Payment.css"
import Footer from "../Footer/Footer";
import SuccessDesktopSvg from "./SuccessDesktopSvg";
const Desktop = () => {
  return (
    <div className='main'>
      <div className='main-main'>
        <div className='main-main-main'>
          <div
            className={`payment-container-done shown`}
            style={{ transform: "translateX(0px)" }}
          >
            <div className='success-div'>
              <div class='animation-ctn'>
                <div class='icon icon--order-success svg'>
                  <SuccessDesktopSvg />
                </div>
              </div>
              {
                <>
                  <h2 style={{ textAlign: "center", marginTop: 60 }}>
                    Payment successful.
                  </h2>
                  <p style={{ textAlign: "center", marginTop: 60 }}>
                    You should receive your invitation email within 24 hours.
                  </p>
                  <p style={{ textAlign: "center", marginTop: 10 }}>
                    If any problem, contact us by email:
                  </p>
                  <h3 style={{ textAlign: "center", marginTop: 20 }}>
                    help@cheapcc.net
                  </h3>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Desktop;
