const Price = ({ currency, prices, duration, AdobePrices}) => {
    return <>
    <div>
    <span style={{ fontSize:14, color:"black", display:'flex', alignSelf:"flex-start", marginBottom:10, fontWeight:'bold' }}>Processing fee:</span>
              <h1 style={{ textWrap: "nowrap", width: "fit-content", fontSize:48 }}>
                <span style={{ fontSize: 28 }}>{currency}</span>{" "}
                {prices[duration]}{" "}
                {duration !== 30 && (
                  <span style={{ fontSize: 14, fontWeight: 400 }}>
                    ({currency}{" "}
                    {duration === 30
                      ? 14
                      : duration === 365
                      ? 12
                      : duration === 730
                      ? 10
                      : duration === 1095
                      ? 9
                      : "???"}
                    {" "}/month)
                  </span>
                )}
              </h1>
              <p style={{ color: "red", fontSize: 18 }}>
                You save{" "}
                <b>
                  {currency}
                  {(AdobePrices[duration] - prices[duration]).toFixed(2)}
                </b>{" "}
                compared to normal prices.
              </p>
            </div>
    </>
}

export default Price
