const constants = {
    API_URL: process.env.REACT_APP_API_URL,
    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    STRIPE_RETURN_URL: process.env.REACT_APP_STRIPE_RETURN_URL,
    MOCK_SERVER_URL: process.env.REACT_APP_MOCK_SERVER_URL,
    SERVER: function (server) {
      if("production" === server) {
        return this.API_URL
      } else if("mock" === server) {
        return this.MOCK_SERVER_URL
      }
    }
  };
  
  export default constants;
  