const EmailInput = ({ email, setEmail }) => {
  return (
    <>
      <div className='input-div-mobile'>
        <label style={{ textAlign: "left", fontSize: 18 }}>
          Adobe account email address:
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div className='input-wrapper-mobile'>
            <input
              style={{ width: "-webkit-fill-available", fontSize: 18 }}
              value={email}
              spellCheck={false}
              required
              onChange={(e) => setEmail(e.target.value)}
              placeholder='jbond@gmail.com'
              type='email'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailInput;
