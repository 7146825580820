const priceHelper = {
  price: {
    _prices: { 30: 14, 365: 144, 730: 240, 1095: 320 },
    _AdobePrices: { 30: 59.99, 365: 659.88, 730: 659.88 * 2, 1095: 659.88 * 3 },
    _pricesDataEur: function (currency) {
      return {
        30:
          currency === "€"
            ? "price_1Q0nKkAvc7OJE1HgnItk70ko"
            : "price_1Q1ojBAvc7OJE1Hg5OQoXyDD",
        365:
          currency === "€"
            ? "price_1Q0nLIAvc7OJE1HgEv3rHARq"
            : "price_1Q1oiuAvc7OJE1HgnEXnNZqw",
        730:
          currency === "€"
            ? "price_1Q0nMSAvc7OJE1HgtkmQqjAN"
            : "price_1Q1oiPAvc7OJE1HgaFePnPAm",
        1095:
          currency === "€"
            ? "price_1Q0nN2Avc7OJE1HgYFajWIaO"
            : "price_1Q1ohsAvc7OJE1Hg9A9ltmAz",
      };
    },
    _pricesDataUs: function (country) {
        return {
          30:
            country === "US"
              ? "price_1Q1ojBAvc7OJE1Hg5OQoXyDD"
              : "price_1Q0nKkAvc7OJE1HgnItk70ko",
          365:
            country === "US"
              ? "price_1Q1oiuAvc7OJE1HgnEXnNZqw"
              : "price_1Q0nLIAvc7OJE1HgEv3rHARq",
          730:
            country === "US"
              ? "price_1Q1oiPAvc7OJE1HgaFePnPAm"
              : "price_1Q0nMSAvc7OJE1HgtkmQqjAN",
          1095:
            country === "US"
              ? "price_1Q1ohsAvc7OJE1Hg9A9ltmAz"
              : "price_1Q0nN2Avc7OJE1HgYFajWIaO",
        };
      },
  },
  
};

export { priceHelper }
