const PayButtonSvg = () => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 100 100'
        preserveAspectRatio='xMidYMid'
        width={25}
        height={25}
        style={{
          shapeRendering: "auto",
          display: "block",
          background: "transparent",
          width: "21.5",
          height: "21.5",
          paddingLeft: 11,
          paddingRight: 11,
          paddingTop: -2,
          paddingBottom: -2,
        }}
      >
        <g>
          <circle
            strokeDasharray='136.659280431156 47.553093477052'
            r='29'
            strokeWidth='10'
            stroke='#ffffff'
            fill='none'
            cy='50'
            cx='50'
          >
            <animateTransform
              keyTimes='0;1'
              values='0 50 50;360 50 50'
              dur='1.3513513513513513s'
              repeatCount='indefinite'
              type='rotate'
              attributeName='transform'
            ></animateTransform>
          </circle>
          <g></g>
        </g>
      </svg>
    </>
  );
};

export default PayButtonSvg;
