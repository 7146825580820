import "../../Main/Faq.css"
import { Link } from "react-router-dom";
import FaqSvg from "./FaqSvg";
const Faq = ({ faqShown, setFaqShown,}) => {
    return <>
    <div className={`faq ${faqShown ? "shown" : ""}`}>
        <div
          onClick={() => setFaqShown(!faqShown)}
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: faqShown ? "flex-start" : "center",
            justifyContent: "space-between",
          }}
        >
          <h1 className={`faq-title ${faqShown ? "withMargin" : ""}`}>FAQ</h1>
          <FaqSvg faqShown={faqShown} />
        </div>
        <div className={`faq-text ${faqShown ? "shown" : ""}`}>
          <h3 style={{ textAlign: "left" }}>
            {faqShown ? "What is CheapCC.net?" : ""}
          </h3>
          <p>
            {faqShown
              ? "CheapCC.net is a site where you can legally get the genuine Adobe CC All Apps plan applied to your own Adobe account, at a much lower price."
              : ""}
          </p>
          <h3 style={{ textAlign: "left", marginTop: 20 }}>
            {faqShown ? "Where does the subscription come from?" : ""}
          </h3>
          <p style={{ marginBottom: 5 }}>
            {faqShown
              ? "In an attempt to gain a foothold in China, Adobe is giving away almost free subscriptions to resellers for distribution to individuals and professionals. Many administrators have started trying to resell them to Westerners. But because they're in China, they have an embargo with many Western countries, so it's very complicated. Most of the time, they can only sell on Chinese marketplaces that are difficult to access from our Western countries."
              : ""}
          </p>
          <p style={{ marginBottom: 5 }}>
            {faqShown
              ? "CheapCC is in contact with the best resellers in China, and acts as a secure gateway hosted in the US, offering the best possible prices on the most stable subscriptions on the market. "
              : ""}
            <b>
              All our suppliers are Adobe-certified Gold or Platinum resellers.
            </b>
          </p>
          <h3 style={{ textAlign: "left", marginTop: 20 }}>
            {faqShown ? "Can my subscription be revoked by Adobe?" : ""}
          </h3>
          <p>
            {faqShown
              ? "The subscriptions we have are geo-unlocked. It means they're not like the Turkish ones that you have to pay with a VPN. It means they can legally be used from anywhere in the world, without violating any terms of service or conditions of use."
              : ""}
          </p>
          <h3 style={{ textAlign: "left", marginTop: 20 }}>
            {faqShown ? "Can my subscription be cancelled?" : ""}
          </h3>
          <p>
            {faqShown
              ? "Our resellers have been around for years and are well known in the industry. They guarantee the full duration for which you paid. Our support team is available daily to address any inquiries or issues, providing replacement subscriptions or refunds if necessary."
              : ""}
          </p>
          {faqShown && (
            <Link
              style={{
                textAlign: "left",
                display: "flex",
                marginLeft: 10,
                width: "100%",
                color: "#596B76",
              }}
              to='/refund-policy'
            >
              See our refund policy
            </Link>
          )}
          <h3 style={{ textAlign: "left", marginTop: 20 }}>
            {faqShown ? "Does anyone has access to my content?" : ""}
          </h3>
          <p>
            {faqShown
              ? "We DO NOT have access to your content created in the Adobe applications. Only Adobe has potential access, particularly when using online features, such as generative AI. Here's an article about the recent updates to Adobe's terms of use on their access to user content:"
              : ""}
          </p>
          {faqShown && (
            <a
              style={{
                textAlign: "left",
                display: "flex",
                marginLeft: 10,
                width: "100%",
                color: "#596B76",
              }}
              href='https://blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use'
            >
              blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use
            </a>
          )}
        </div>
      </div>
    </>
}

export default Faq;