import { Route, Routes, Navigate } from "react-router-dom";
import React, { useEffect } from "react";
import Main from "../Main/Main";
import Refund from "../Refund/Refund";
import TermsOfService from "../TermsOfService/TermsOfService";
import PrivacyNotice from "../PrivacyNotice/PrivacyNotice";
import Bulk from "../Bulk/Bulk";
import Success from "../Success/Success";
import { Toaster } from "react-hot-toast";
import useAuthStore from "../../store/authStore";
import Private from "../Private/Private";
import Dashboard from "../Dashboard/Dashboard";

export default function App() {
  const fetchAuthState = useAuthStore((state) => state.fetchAuthState);
  useEffect(() => {
    fetchAuthState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/bulk" element={<Bulk />} />
        <Route path="/success" element={<Success />} />
        <Route path="/refund-policy" element={<Refund />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/privacy-notice" element={<PrivacyNotice />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route element={<Private />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}
