import { CardExpiryElement } from "@stripe/react-stripe-js";

const ExpiryDate = () => {
  return (
    <>
      <div className='card-subwrapper'>
        <h4
          style={{
            textWrap: "nowrap",
            fontSize: 14,
            width: "fit-content",
            margin: 0,
            marginBottom: 10,
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            color: "#30313D",
          }}
        >
          Expiry date
        </h4>
        <CardExpiryElement
          options={{
            showIcon: true,
            classes: {
              base: "cardElement expiry",
              focus: "cardElement focus",
            },
          }}
        />
      </div>
    </>
  );
};

export default ExpiryDate;
